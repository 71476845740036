export default class TrustindexLightboxModule {
	constructor(widget)
	{
		this.data = widget.TrustindexReviewImageModule.data;
		this.widget = widget;
	}

	init()
	{
		let html = `<div class="ti-widget-lightbox">
			<div class="ti-widget-lightbox-close"></div>
			<div class="ti-widget-lightbox-inner">
				<div class="ti-widget-card">
					<div class="ti-card-body">
						<div class="ti-card-media">
							<img alt="" />
							<div class="ti-nav-arrow">
								<div class="ti-arrow-prev"></div>
								<div class="ti-arrow-next"></div>
							</div>
						</div>
						<div class="ti-review-content"></div>
					</div>
				</div>
			</div>
		</div>`;

		this.lightbox = (new DOMParser()).parseFromString(html, 'text/html').querySelector('.ti-widget-lightbox');
		this.image = this.lightbox.querySelector('.ti-card-media img');
		this.reviewContent = this.lightbox.querySelector('.ti-review-content');
		this.prevArrow = this.lightbox.querySelector('.ti-arrow-prev');
		this.nextArrow = this.lightbox.querySelector('.ti-arrow-next');
		this.currentIndex = 0;
		this.currentReview = null;

		this.lightbox.style.fontFamily = window.getComputedStyle(this.widget.container, null).getPropertyValue('font-family');

		this.addEvents();
	}

	addEvents()
	{
		this.image.onload = () => this.image.parentNode.classList.remove('ti-image-loading');

		let touchstartX, touchmoveX, touchstartY, touchmoveY;
		let isSwiping = false;
		let isScrolling = false;

		this.image.addEventListener('touchstart', (event) => {
			touchstartX = event.touches[0].pageX;
			touchstartY = event.touches[0].pageY;
			touchmoveX = null;
			touchmoveY = null;
			isSwiping = false;
			isScrolling = false;
		}, { passive: true });

		this.image.addEventListener('touchmove', (event) => {
			touchmoveX = event.touches[0].pageX;
			touchmoveY = event.touches[0].pageY;

			let disX = touchmoveX - touchstartX;
			let disY = touchmoveY - touchstartY;

			if (Math.abs(disY) > 10 && !isSwiping) {
				isScrolling = true;
			}

			if (!isScrolling && (Math.abs(disX) > 10 || isSwiping)) {
				isSwiping = true;
			}
		}, { passive: true });

		this.image.addEventListener('touchend', (event) => {
			if (isSwiping && !isScrolling && touchstartX && touchmoveX && Math.abs(touchstartX - touchmoveX) > 25) {
				let index = this.currentIndex - 1;
				if (touchstartX > touchmoveX) {
					index = this.currentIndex + 1;
				}

				this.change(index);
			}

			touchstartX = null;
			touchmoveX = null;
			isSwiping = false;
			isScrolling = false;

			return false;
		}, { passive: true });

		this.lightbox.addEventListener('click', (event) => {
			event.preventDefault();
			event.stopPropagation();

			if (event.target.matches('.ti-arrow-prev')) {
				this.change(this.currentIndex - 1);
			} else if (event.target.matches('.ti-arrow-next')) {
				this.change(this.currentIndex + 1);
			} else if ((event.target.matches('.ti-widget-lightbox') || event.target.matches('.ti-widget-lightbox-close')) && !event.target.closest('.ti-widget-lightbox-inner')) {
				this.close();
			}
		});
	}

	show(imageElement)
	{
		if (typeof this.lightbox === 'undefined') {
			this.init();
		}

		console.log('[Trustindex Widget: '+this.widget.pid+' - '+this.widget.layoutId+'] lightbox: show');

		this.widget.isLightboxShowing = true;

		document.body.style.overflow = 'hidden';
		document.querySelectorAll('.ti-widget-lightbox').forEach(lightbox => lightbox.remove());

		let reviewHash = imageElement.closest('.ti-review-item').getAttribute('data-id');
		let currentIndex = 0, index = parseInt(imageElement.getAttribute('data-image-index'));
		for (let i = 0, l = this.data.length; i < l; i++) {
			if (this.data[i].r === reviewHash && this.data[i].index === index) {
				currentIndex = i;
				break;
			}
		}

		document.body.appendChild(this.lightbox);

		this.change(currentIndex);

		setTimeout(() => this.lightbox.classList.add('ti-widget-lightbox-appeared'), 10);
	}

	close()
	{
		console.log('[Trustindex Widget: '+this.widget.pid+' - '+this.widget.layoutId+'] lightbox: close');

		this.lightbox.classList.remove('ti-widget-lightbox-appeared');
		setTimeout(() => this.lightbox.remove(), 300);

		document.body.style.overflow = '';

		delete this.widget.isLightboxShowing;
	}

	change(index)
	{
		console.log('[Trustindex Widget: '+this.widget.pid+' - '+this.widget.layoutId+'] lightbox: change');

		this.currentIndex = index;

		this.image.parentNode.classList.add('ti-image-loading');

		let src = this.data[this.currentIndex].src;
		if (src.indexOf('google') !== -1) {
			src = src.replace(/=w.+/, '=s' + Math.round(this.image.parentNode.offsetHeight * Trustindex.getDevicePixelRatio()));
		}

		this.image.src = src;

		if (this.currentReview !== this.data[this.currentIndex].r) {
			this.currentReview = this.data[this.currentIndex].r;
			this.reviewContent.innerHTML = "";

			let reviewItem = this.widget.querySelector('.ti-review-item[data-id="'+this.data[this.currentIndex].r+'"]');
			if (!reviewItem) {
				return;
			}

			let reviewHeader = reviewItem.querySelector('.ti-review-header').cloneNode(true);
			reviewHeader.classList.add('ti-lightbox-review-header');

			let reviewText = reviewItem.querySelector('.ti-review-text-container').cloneNode(true);
			reviewText.setAttribute('class', 'ti-review-text')
			reviewText.setAttribute('style', "");
			reviewText.querySelectorAll('.ti-review-image').forEach(img => img.remove());

			this.reviewContent.appendChild(reviewHeader);
			this.reviewContent.appendChild(reviewItem.querySelector('.ti-stars').cloneNode(true));
			this.reviewContent.appendChild(reviewText);

			this.lightbox.querySelector('.ti-widget-card').setAttribute('class', reviewItem.getAttribute('class').replace('ti-review-item', 'ti-widget-card'));
		}

		this.prevArrow.toggleHide();
		this.nextArrow.toggleHide();

		if (index > 0) {
			this.prevArrow.toggleShow();
		}

		if (index < this.data.length - 1) {
			this.nextArrow.toggleShow();
		}
	}

	click(event)
	{
		if (event.target.matches('.ti-review-image')) {
			event.preventDefault();

			this.show(event.target);
		}
	}
};