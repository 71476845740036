export default class TrustindexReviewWidget {
	constructor(widget)
	{
		widget.reviewTextMode = widget.getAttribute('data-review-text-mode') || 'readmore';
		widget.reviewsContainer = widget.querySelector('.ti-reviews-container');
		widget.reviewsContainerWrapper = widget.querySelector('.ti-reviews-container-wrapper');
		widget.locales.time = widget.getAttribute('data-time-locale');
		widget.locales.replyBy = widget.getAttribute('data-reply-by-locale');

		widget.isCompanyBadge = widget.container.offsetWidth > widget.reviewsContainer.offsetWidth;
		widget.reviewTargetWidth = parseInt(widget.getAttribute('data-review-target-width') || (widget.isCompanyBadge ? 275 : 300));

		this.widget = widget;
		this.widget.originalColCount = this.getColCount();

		this.initHeaderClick();
	}

	format()
	{
		this.widget.isCompanyBadge = this.widget.container.offsetWidth > this.widget.reviewsContainer.offsetWidth;

		this.widget.reviewsContainer.querySelectorAll('.ti-review-item').forEach(reviewItem => this.formatReview(reviewItem));
	}

	formatReview(reviewItem)
	{
		let textContainer = reviewItem.querySelector('.ti-review-text-container');
		if (textContainer) {
			this.formatReviewText(textContainer);

			// IOS fallback for review images with float inside -webkit-box
			//	- as of 2024-07-30 float not working in -webkit-box so we change review-text-container to block to work
			if (!textContainer.classList.contains('ti-empty-text')) {
				textContainer.querySelectorAll('.ti-review-image').forEach(imageContainer => {
					// replace text with <span> so we can check if it placed under the image
					let span = document.createElement('span');
					let textNode = imageContainer.parentNode.childNodes[1];
					let textContent = textNode.textContent;

					span.innerHTML = textContent;
					textNode.replaceWith(span);

					// text placed under the image if top position is not equal with the container's top position
					if (span.offsetTop - imageContainer.parentNode.offsetTop > 20) {
						this.widget.classList.add('ti-ios-fallback');
					}

					// replace back
					span.replaceWith(textNode);
				});
			}
		}

		// page url open on name click
		let url = reviewItem.getAttribute('data-platform-page-url');
		if (url) {
			let name = reviewItem.querySelector('.ti-name');
			if (name) {
				name.style.cursor = 'pointer';
				name.addEventListener('click', event => Trustindex.openWindow(url));
			}
		}

		// modern date (x day/week/month/year ago)
		let timeStamp = reviewItem.getAttribute('data-time');
		if (timeStamp) {
			let date = reviewItem.querySelector('.ti-date:not(.ti-date-comment)');
			if (date) {
				date.innerHTML = Trustindex.getRelativeTime(parseInt(timeStamp), this.widget.locales.time);
			}
		}

		// replace wrong profile images
		reviewItem.querySelectorAll('.ti-profile-img img').forEach(img => {
			if (img.complete && (typeof img.naturalWidth === 'undefined' || 0 === img.naturalWidth)) {
				img.setAttribute('src', Trustindex.getDefaultAvatarUrl());
				img.removeAttribute('srcset');
			} else {
				let errorCallback = i => {
					i = i.target || i;

					i.setAttribute('src', Trustindex.getDefaultAvatarUrl());
					i.removeAttribute('srcset');
					i.removeEventListener('error', errorCallback);
				};

				img.removeEventListener('error', errorCallback);
				img.addEventListener('error', errorCallback);
			}
		});
	}

	formatReviewText(textContainer)
	{
		let svgs = textContainer.querySelectorAll('svg');
		if (0 === svgs.length) {
			let html = textContainer.innerHTML;

			// PRO-KONTRA - Wordpress Plugins
			html = html
				.replace(/<img.+class="emoji" alt="\u263a" src="[^'"]+">/gm, Trustindex.svgs.good + '&nbsp;&middot;&nbsp;')
				.replace(/<img.+class="emoji" alt="\u2639" src="[^'"]+">/gm, Trustindex.svgs.bad + '&nbsp;&middot;&nbsp;');

			// PRO-KONTRA
			if (textContainer.closest('.ti-review-item').classList.contains('source-Booking')) {
				html = html
					.replace(/\u263a/g, Trustindex.svgs.good + '&nbsp;&middot;&nbsp;')
					.replace(/\u2639/g, Trustindex.svgs.bad + '&nbsp;&middot;&nbsp;');
			}

			// nl2brs
			html = html.replace(/\n/g, '<br />');

			textContainer.innerHTML = Trustindex.getDecodedHtml(html);

			svgs = textContainer.querySelectorAll('svg');
		}

		if (svgs.length) {
			let size = parseInt(textContainer.style.fontSize || 14) * 0.95;
			svgs.forEach(svg => {
				svg.style.width = size + 'px';
				svg.style.height = size + 'px';
			});
		}
	}

	resize()
	{
		let containerWidth = this.widget.reviewsContainer.offsetWidth;
		if (this.widget.isCompanyBadge && typeof this.widget.TrustindexSliderWidget !== 'undefined' && this.widget.TrustindexSliderWidget.footer) {
			if (undefined === this.companyBadgeWidth) {
				this.companyBadgeWidth = this.widget.TrustindexSliderWidget.footer.offsetWidth;

				let style = window.getComputedStyle(this.widget.TrustindexSliderWidget.footer, null);
				this.companyBadgeWidth += parseInt(style.getPropertyValue('margin-left'));
				this.companyBadgeWidth += parseInt(style.getPropertyValue('margin-right'));
			}

			containerWidth = this.widget.container.offsetWidth - this.companyBadgeWidth;
		}

		let colCount = Math.floor(containerWidth / this.widget.reviewTargetWidth);

		if (colCount > 1 && 44 === this.widget.layoutId) {
			colCount--;
		}

		if (this.widget.isCompanyBadge) {
			if (containerWidth < 350) {
				colCount = 0;
			}

			this.widget.container.setAttribute('class', 'ti-widget-container ti-col-' + (colCount + 1));
		}
		else {
			if (colCount < 1) {
				colCount = 1;
			}

			this.widget.container.setAttribute('class', 'ti-widget-container ti-col-' + colCount);
		}

		console.log('[Trustindex Widget: '+this.widget.pid+' - '+this.widget.layoutId+'] resized');
	}

	resizeAfter()
	{
		// set width for verified review tooltips
		let toolTipWidth = 0;
		this.widget.reviewsContainer.querySelectorAll('.ti-verified-review.ti-verified-platform').forEach(icon => {
			let tooltip = icon.querySelector('.ti-verified-tooltip');

			if (!toolTipWidth) {
				let style = getComputedStyle(tooltip);

				toolTipWidth = icon.parentNode.offsetWidth - icon.offsetLeft - 5;
				if (parseInt(style.left) < 0) {
					toolTipWidth = icon.offsetLeft - icon.parentNode.offsetLeft - 5;
				}
			}

			tooltip.style.width = toolTipWidth + 'px';
		});
	}

	click(event)
	{
		if (event.target.matches('.ti-show-original-text')) {
			event.preventDefault();
			event.stopPropagation();

			let reviewTextContainer = event.target.closest('.ti-review-text-container');
			let json = JSON.parse(reviewTextContainer.querySelector('script[type="application/ld+json"]').innerHTML);
			let text = json.text || json;

			let reviewImage = reviewTextContainer.querySelector('.ti-review-image');
			if (reviewImage) {
				text = reviewImage.outerHTML + text;
			}

			if (typeof json.reply !== 'undefined' && json.reply) {
				text += '<br /><br /><strong class="ti-reply-by-owner-title">'+this.widget.locales.replyBy+'</strong><br />'+json.reply;
			}

			reviewTextContainer.innerHTML = text;
			this.formatReviewText(reviewTextContainer);

			if (typeof this.widget.TrustindexReadMoreModule !== 'undefined') {
				this.widget.TrustindexReadMoreModule.resetReview(reviewTextContainer);
			}
		}
	}

	initHeaderClick()
	{
		let a = this.widget.querySelector('a[href]:not(.ti-write-btn-dropdown-item):not(.ti-header-write-btn)');
		if (!a) {
			return;
		}

		let url = a.getAttribute('href');
		if (!url || '#' === a.getAttribute('href')) {
			return;
		}

		let container = a.closest('.ti-header:not(a), .ti-footer:not(a), .ti-popup-header:not(a)');
		if (!container || !container.querySelector('.ti-large-logo, .ti-profile-img, .ti-profile-details, .ti-logo-stars-flex') || container.querySelector('.ti-disclaimer')) {
			return;
		}

		container.classList.add('ti-clickable-link');
		container.addEventListener('click', (event) => {
			if ('A' === event.target.nodeName) {
				return false;
			}

			event.preventDefault();
			Trustindex.openWindow(url);
		});
	}

	getColCount()
	{
		let className = this.widget.container.classList.toString();

		if (-1 === className.indexOf('ti-col-')) {
			return 1;
		}

		return parseInt(className.replace(/^.*ti-col-(\d+).*$/, '$1'));
	}
};