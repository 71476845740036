export default class TrustindexTopRatedWidget {
	constructor(widget)
	{
		this.disclaimer = widget.querySelector('.ti-disclaimer');
		this.widget = widget;

		this.addEvents();
	}

	addEvents()
	{
		this.widget.querySelector('.ti-verified-by').addEventListener('mouseenter', () => {
			this.disclaimer.classList.remove('ti-bottom');

			if (this.disclaimer.getBoundingClientRect().y < 0) {
				this.disclaimer.classList.add('ti-bottom');
			}
		});
	}
};