export default class TrustindexMasonryWidget {
	constructor(widget)
	{
		// force original col count for masonry grid
		widget.originalColCount = 3;

		this.widget = widget;
	}

	resize()
	{
		let colCount = this.widget.TrustindexReviewWidget.getColCount();

		let reviews = this.widget.container.querySelectorAll('.ti-review-item:not([style*="display: none"])');
		let hiddenReviews = this.widget.container.querySelectorAll('.ti-review-item[style*="display: none"]');

		this.widget.reviewsContainerWrapper.innerHTML = "";
		for (var i = 0, cols = []; i < colCount; i++) {
			cols[i] = document.createElement('div');
			cols[i].setAttribute('class', 'ti-column');
			this.widget.reviewsContainerWrapper.appendChild(cols[ i ]);
		}

		Array.from(reviews).sort(function(a, b) {
			let aIndex = a.getAttribute('data-index') ? parseInt(a.getAttribute('data-index')) : 0;
			let bIndex = b.getAttribute('data-index') ? parseInt(b.getAttribute('data-index')) : 1;

			return aIndex - bIndex;
		}).forEach((reviewItem, i) => cols[i % colCount].appendChild(reviewItem));

		hiddenReviews.forEach(reviewItem => cols[0].appendChild(reviewItem));
	}
};