export default class TrustindexLoadMoreModule {
	constructor(widget)
	{
		this.widget = widget;
		this.callOneOriginalColumnResize = true;

		this.rows = parseInt(this.widget.getAttribute('data-load-more-rows') || (this.isGrid() ? 3 : 5));
	}

	resize()
	{
		let button = this.widget.querySelector('.ti-load-more-reviews-button');
		let limit = this.getLimit();

		button.toggleShow();
		this.widget.reviewsContainer.querySelectorAll('.ti-review-item').forEach(r => r.classList.remove('ti-hide'));

		let reviews = this.widget.reviewsContainer.querySelectorAll('.ti-review-item:not([style*="display: none"])');
		if (reviews.length > limit) {
			if (typeof this.widget.TrustindexMasonryWidget !== 'undefined') {
				let columns = this.widget.reviewsContainerWrapper.querySelectorAll('.ti-column');
				limit = limit / this.widget.TrustindexReviewWidget.getColCount();

				for (let i = 0; i < columns.length; i++) {
					reviews = columns[i].querySelectorAll('.ti-review-item:not([style*="display: none"])')
					this.hideReviews(reviews, limit);
				}
			} else {
				this.hideReviews(reviews, limit);
			}
		} else {
			button.toggleHide();
		}
	}

	click(event)
	{
		if (event.target.matches('.ti-load-more-reviews-button')) {
			event.preventDefault();

			let button = event.target;
			let limit = this.getLimit();

			if (typeof this.widget.TrustindexMasonryWidget !== 'undefined') {
				let columns = this.widget.reviewsContainerWrapper.querySelectorAll('.ti-column');
				limit = limit / this.widget.TrustindexReviewWidget.getColCount();

				for (let i = 0; i < columns.length; i++) {
					this.showReviews(columns[i].querySelectorAll('.ti-review-item.ti-hide'), limit);
				}
			} else {
				this.showReviews(this.widget.querySelectorAll('.ti-review-item.ti-hide'), limit);
			}

			if (0 === this.widget.querySelectorAll('.ti-review-item.ti-hide').length) {
				button.toggleHide();
			}
		}
	}

	isGrid()
	{
		let className = this.widget.container.classList.toString();
		if (-1 === className.indexOf('ti-col-') || 81 === this.widget.layoutId) {
			return false;
		}

		return true;
	}

	getLimit()
	{
		return this.isGrid() ? this.widget.TrustindexReviewWidget.getColCount() * this.rows : this.rows;
	}

	hideReviews(reviews, limit)
	{
		reviews.forEach((review, i) => {
			if (i > limit - 1) {
				review.classList.add('ti-hide');
			}
		});
	}

	showReviews(reviews, limit)
	{
		for (let i = 0; i < reviews.length; i++) {
			if (i >= limit) {
				break;
			}

			reviews[i].classList.remove('ti-hide');

			if (typeof this.widget.TrustindexReadMoreModule !== 'undefined') {
				this.widget.TrustindexReadMoreModule.setReview(reviews[i].querySelector('.ti-review-text-container'));
			}
		}
	}
};