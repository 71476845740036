export default class TrustindexReadMoreModule {
	constructor(widget)
	{
		this.widget = widget;
		this.callOneOriginalColumnResize = true;
	}

	resize(forceResize, setAllReview = true)
	{
		// TODO: delete after some time (backward compatibility - implemented in 2024-10-02)
		if (typeof this.isReadmore === 'undefined') {
			this.isReadmore = window.getComputedStyle(this.widget.reviewsContainer.querySelector('.ti-read-more'), null).getPropertyValue('display') !== 'undefined';
		}

		// TODO: delete after some time (backward compatibility - implemented in 2024-10-02)
		if (!this.isReadmore) {
			return;
		}

		let buttons = this.widget.reviewsContainer.querySelectorAll('.ti-review-item:not(.ti-hide) .ti-read-more');

		if (setAllReview) {
			buttons.forEach(button => this.setReview(button.closest('.ti-review-item').querySelector('.ti-review-text-container')));
		}

		if (buttons.length === this.widget.reviewsContainer.querySelectorAll('.ti-read-more[style*="opacity: 0"]').length) {
			buttons.forEach(button => button.toggleHide());
		}
	}

	setReview(reviewTextContainer, isReturn = false)
	{
		let reviewItem = reviewTextContainer.closest('.ti-review-item');
		if (!reviewItem) {
			return;
		}

		let button = reviewItem.querySelector('.ti-read-more');

		button.setAttribute('style', '');
		reviewTextContainer.setAttribute('style', 'transition: none');

		let isReadmore = reviewTextContainer.scrollHeight > reviewTextContainer.offsetHeight;

		if (isReadmore) {
			reviewTextContainer.setAttribute('style', '');
			reviewTextContainer.style.setProperty('height', reviewTextContainer.offsetHeight + 'px', 'important');
			reviewTextContainer.setAttribute('data-initial-height', reviewTextContainer.offsetHeight);
			reviewTextContainer.setAttribute('data-expanded-height', this.getExpandedHeight(reviewTextContainer));

			if (reviewTextContainer.parentNode.classList.contains('ti-review-content')) {
				reviewTextContainer.parentNode.style.display = 'block';
				reviewTextContainer.parentNode.style.setProperty('-webkit-line-clamp', 'unset', 'important');
			}

			// unset container max-height if given
			if (!reviewTextContainer.getAttribute('data-max-height') && parseInt(window.getComputedStyle(reviewTextContainer, null).getPropertyValue('max-height')) > 0) {
				reviewTextContainer.style.setProperty('max-height', 'unset', 'important');
			}

			if (button.getAttribute('data-open-text')) {
				button.innerHTML = '<span class="ti-read-more-active">' + button.getAttribute('data-open-text') + '</span>';
			}

			button.setAttribute('style', '');
		} else {
			// just hide it with opacity (for sliders, CSS will do it for others)
			button.innerHTML = '<span>&nbsp;</span>';
			button.style.opacity = 0;
			button.style.pointerEvents = 'none';
		}

		if (isReturn) {
			return;
		}

		setTimeout(() => this.setReview(reviewTextContainer, true), 4);
	}

	resetReview(reviewTextContainer)
	{
		if (reviewTextContainer.getAttribute('data-is-opened')) {
			reviewTextContainer.style.setProperty('height', '');
			reviewTextContainer.style.setProperty('transition', 'none');

			reviewTextContainer.style.setProperty('height', reviewTextContainer.scrollHeight + 'px', 'important');
			reviewTextContainer.setAttribute('data-expanded-height', this.getExpandedHeight(reviewTextContainer));
			reviewTextContainer.style.setProperty('transition', '');
		} else {
			this.setReview(reviewTextContainer);
			this.resize(true, false);
		}
	}

	click(event)
	{
		if (event.target.matches('.ti-read-more-active')) {
			event.preventDefault();

			let button = event.target;
			let reviewTextContainer = button.closest('.ti-review-item').querySelector('.ti-review-text-container');

			if (reviewTextContainer.classList.contains('ti-is-transitioning')) {
				return false;
			}

			if (button.classList.contains('ti-read-more-collapse')) {
				reviewTextContainer.style.setProperty('display', '');
				reviewTextContainer.style.setProperty('-webkit-line-clamp', '');
				reviewTextContainer.style.setProperty('height', reviewTextContainer.getAttribute('data-initial-height') + 'px', 'important');
				reviewTextContainer.removeAttribute('data-is-opened');

				reviewTextContainer.classList.add('ti-is-transitioning');
				setTimeout(() => reviewTextContainer.classList.remove('ti-is-transitioning'), 500);

				button.innerHTML = button.parentNode.getAttribute('data-open-text');
				button.classList.remove('ti-read-more-collapse');
			} else {
				reviewTextContainer.style.setProperty('display', 'block', 'important');
				reviewTextContainer.style.setProperty('-webkit-line-clamp', 'unset', 'important');
				reviewTextContainer.style.setProperty('height', reviewTextContainer.getAttribute('data-expanded-height') + 'px', 'important');
				reviewTextContainer.setAttribute('data-is-opened', 1);

				reviewTextContainer.classList.add('ti-is-transitioning');
				setTimeout(() => reviewTextContainer.classList.remove('ti-is-transitioning'), 500);

				button.innerHTML = button.parentNode.getAttribute('data-collapse-text');
				button.classList.add('ti-read-more-collapse');
			}
		}
	}

	getExpandedHeight(reviewTextContainer)
	{
		let min = reviewTextContainer.scrollHeight;
		if (reviewTextContainer.getAttribute('data-max-height')) {
			min = parseInt(window.getComputedStyle(reviewTextContainer, null).getPropertyValue('max-height'));
		}

		return Math.min(reviewTextContainer.scrollHeight, min);
	}
};