import TrustindexCommon from '../../TrustindexCommon.js';

export default class Trustindex extends TrustindexCommon {
	// for backward compatibility - TODO: delete after some time (created 2024-09-26)
	static resize_widgets() {}
	static init() { }
	static init_pager() {
		// for older Testimonials WP plugin
		if (-1 !== decodeURIComponent(location.href).indexOf('page=testimonial-widgets/tabs/create-widget.php')) {
			this.destroyWidgets();
			this.initWidgetsFromDom(true);
		}
	}

	static setConstants()
	{
		this.setCommonConstants();

		this.svgs = {
			good: '<svg style="display: inline-block; vertical-align: sub;fill: #0ab21b;position:relative;top:-2px" viewBox="0 0 128 128"><path d="M64 8a56 56 0 1 0 56 56A56 56 0 0 0 64 8zm0 104a48 48 0 1 1 48-48 48 48 0 0 1-48 48zM44 64a8 8 0 1 1 8-8 8 8 0 0 1-8 8zm48-8a8 8 0 1 1-8-8 8 8 0 0 1 8 8zm-4.8 21.6a4 4 0 0 1 .6 3.6A24.3 24.3 0 0 1 64 97c-9.7 0-15.7-4.2-19-7.8a22.7 22.7 0 0 1-4.8-8A4 4 0 0 1 44 76h40a4 4 0 0 1 3.2 1.6z"></path></svg>',
			bad: '<svg style="display: inline-block; vertical-align: sub;fill: #383838;margin-top: -1px;position:relative;top:-2px" viewBox="0 0 128 128"><path d="M64 8a56 56 0 1 0 56 56A56 56 0 0 0 64 8zm0 104a48 48 0 1 1 48-48 48 48 0 0 1-48 48zM44 64a8 8 0 1 1 8-8 8 8 0 0 1-8 8zm48-8a8 8 0 1 1-8-8 8 8 0 0 1 8 8zm-5.2 30.2a4 4 0 1 1-5.6 5.6c-10.5-10.4-24-10.4-34.4 0a4 4 0 0 1-5.6-5.6c13.6-13.7 32-13.7 45.6 0z"></path></svg>',
		};

		this.additionalLogoStyles = {
			'header-logo': {
				'Google': 'height: 25px; top: -2px',
				'Facebook': 'height: 17px',
				'Trustindex': 'height: 19px',
				'Tripadvisor': 'height: 24px; top: -2px',
				'Airbnb': 'height: 26px; top: -3px',
				'Yelp': 'height: 32px; top: -8px',
				'Amazon': 'height: 13px; top: 1px',
				'Arukereso': 'height: 17px; top: 5px',
				'Vrbo': 'height: 20px; top: -2px',
				'BBB': 'height: 25px; top: -2px',
				'Expedia': 'height: 25px; top: -3px',
				'Ebay': 'height: 25px; top: -2px',
				'Sitejabber': 'height: 20px; top: 1px',
				'Thumbtack': 'height: 25px; top: -3px',
				'Provenexpert': 'height: 25px; top: 1px',
				'Hotels': 'height: 22px; top: -1px',
				'Zillow': 'height: 17px',
				'Yell': 'height: 18px',
				'Checkatrade': 'height: 15px; top: 2px',
				'Capterra': 'height: 15px; top: 2px',
				'Etsy': 'height: 20px; top: 1px',
				'WordpressPlugin': 'height: 13px; top: 1px',
				'AngiesList': 'height: 24px; top: -1px',
				'OnlinePenztarca': 'height: 30px; top: -3px',
				'ConsumerAffairs': 'height: 30px; top: -3px',
				'Reviewsio': 'height: 20px; top: 0px',
				'Foursquare': 'height: 15px; top: 3px',
				'Justdial': 'height: 15px; top: 3px',
				'Houzz': 'height: 20px; top: -3px',
				'Indeed': 'height: 17px; top: 0px',
			},
			'large-logo': {
				'Facebook': 'width: 145px; height: 28px',
				'Google': 'width: 110px; height: 35px',
				'Yelp': 'width: 95px; height: 46px; top: -5px',
				'Airbnb': 'width: 120px; height: 38px',
				'Tripadvisor': 'width: 155px; height: 33px',
				'Trustpilot': 'width: 140px; height: 34px',
				'Hotels': 'width: 152px; height: 30px',
				'Amazon': 'width: 120px; height: 25px',
				'Bookatable': 'height: 27px; left: 7px',
				'Foursquare': 'height: 20px; top: 3px',
				'Trustindex': 'width: 152px; height: 26px; top: 3px',
				'Booking': 'height: 25px',
				'Opentable': 'height: 35px',
				'Capterra': 'height: 34px',
				'Thumbtack': 'height: 34px',
				'Fresha': 'height: 21px',
				'Expedia': 'height: 44px',
				'Mobilede': 'height: 35px',
				'Checkatrade': 'height: 22px',
				'Pricerunner': 'height: 21px',
				'Easyweddings': 'height: 50px; top: 3px',
				'Whichtrustedtraders': 'width: 80px; height: 55px; top: -15px; left: 10px',
				'Abia': 'width: 100px; height: 41px',
				'WordpressPlugin': 'height: 35px',
				'Zoover': 'height: 22px',
				'Arukereso': 'height: 25px',
				'Arukereso-ro': 'height: 29px',
				'Pricespy': 'height: 39px',
				'Pricespy-dk': 'height: 42px',
				'Pricespy-fi': 'height: 35px',
				'Pricespy-fr': 'height: 31px',
				'Pricespy-no': 'height: 43px',
				'Szallashu': 'height: 26px',
				'Szallashu-com': 'height: 29px',
				'Szallashu-cz': 'height: 47px',
				'Szallashu-pl': 'height: 34px',
				'Szallashu-ro': 'height: 29px',
			},
			'small-logo': {
				'Facebook': 'width: 72px; height: 15px; top: 0px',
				'Google': 'width: 62px; height: 20px; top: 1px',
				'Yelp': 'width: 45px; height: 22px; top: -3px',
				'Airbnb': 'width: 64px; height: 20px',
				'Tripadvisor': 'width: 97px; height: 21px; top: 1px',
				'Trustpilot': 'width: 65px; top: 2px',
				'Hotels': 'width: 100px; height: 20px',
				'Amazon': 'width: 68px; height: 20px;',
				'Bookatable': 'width: 83px; height: 15px; top: 1px',
				'Foursquare': 'width: 98px; height: 13px; top: 3px',
				'Trustindex': 'width: 95px; top: 2px',
				'Booking': 'width: 89px; height: 15px; top: 2px',
				'Opentable': 'width: 100px; height: 23px; top: -1px',
				'Capterra': 'width: 79px; height: 18px; top: 1px',
				'Thumbtack': 'width: 88px; height: 20px',
				'Fresha': 'width: 93px; height: 13px; top: 2px; left: 1px',
				'Expedia': 'width: 69px; height: 20px; top: 1px',
				'Zillow': 'width: 76px',
				'Reco': 'width: 76px',
				'Mobilede': 'width: 68px; top: 2px',
				'Checkatrade': 'width: 110px',
				'Pricerunner': 'width: 102px; height: 14px; top: 1px',
				'Easyweddings': 'width: 60px; height: 20px',
				'Whichtrustedtraders': 'width: 34px; height: 23px; top: -1px',
				'Abia': 'width: 40px',
				'Ebay': 'top: 2px',
				'WordpressPlugin': 'height: 12px; top: 1px',
				'Arukereso': 'width: 92px; height: 15px; top: 3px',
				'Arukereso-bg': 'width: 89px; height: 15px; top: 3px',
				'Arukereso-ro': 'width: 77px; height: 15px; top: 3px',
				'Pricespy': 'width: 69px; height: 18px',
				'Pricespy-dk': 'width: 64px; height: 18px',
				'Pricespy-fi': 'width: 77px; height: 18px',
				'Pricespy-fr': 'width: 88px; height: 18px',
				'Pricespy-no': 'width: 63px; height: 18px',
				'Pricespy-nu': 'width: 63px; height: 18px',
				'Szallashu': 'width: 76px; height: 13px; top: 2px',
				'Szallashu-com': 'width: 95px; height: 18px',
				'Szallashu-cz': 'width: 61px; height: 19px; top: 3px',
				'Szallashu-pl': 'width: 74px; height: 17px; top: 3px',
				'Szallashu-ro': 'width: 100px; height: 19px',
			},
		};

		this.richSnippetLoaded = document.querySelectorAll('script[src*=".trustindex.io/assets/js/richsnippet.js"], script[type="application/ld+json"][data-trustindex="1"]').length > 0;
	}

	static async loadWidgetsFromDom()
	{
		document.querySelectorAll(this.getScriptSelector('loader')).forEach(scriptElement => {
			if (scriptElement.getAttribute('data-ti-widget-inited')) {
				return console.log('[Trustindex Widget] content already inited');
			}

			scriptElement.setAttribute('data-ti-widget-inited', true);

			// get key
			let key = this.getScriptKey(scriptElement);
			if (!key) {
				return console.log('[Trustindex Widget] key not found');
			}

			let isActivityLoadEnabled = Boolean(scriptElement.getAttribute('data-enable-delay-load'));

			console.log('[Trustindex Widget] key found:', key);

			// change <script> to <div> so we will have DOM positions
			if ('SCRIPT' === scriptElement.tagName) {
				let div = document.createElement('div');
				div.key = scriptElement.key;

				scriptElement.replaceWith(div);
				scriptElement = div;
			}

			scriptElement.key = key;
			scriptElement.isTriggerLoad = function(distance = 600) {
				let bounding = this.getBoundingClientRect();

				if (bounding.right <= (window.innerWidth || document.documentElement.clientWidth) + distance && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) + distance) {
					return true;
				}

				return false;
			};

			(async (element) => {
				let html = await this.getWidgetHtml(element);

				if (false === html) { // widget not found
					return element.innerHTML = `
						Widget not found! Probably it is already deleted or there is typo in its ID.
						We suggest that you log in to the <a href="https://admin.trustindex.io/" target="_blank">Trustindex system</a> and follow the widget configuration instructions.
						Or, if you don't have an account, create one for free at <a href="https://www.trustindex.io?a=sys&c=widget-not-found" target="_blank">www.trustindex.io</a>
					`;
				} else if (!html) { // empty widget
					return element.replaceWith(document.createComment('Trustindex widget ('+ element.key +') is empty here.'));
				} else if (/style="[^"]*border: 4px dashed red[^"]*"/.test(html)) { // expired widget
					return element.innerHTML = html;
				}

				if (!isActivityLoadEnabled) {
					isActivityLoadEnabled = html.indexOf('data-delay-load="1" class="ti-widget') !== -1;
				}

				let isFloatingWidget = /(?:data-layout-category="[^"]*floating[^"]*"|data-layout-id="(?:17|21|52|53)")/.test(html);
				if (element.closest('head') && !isFloatingWidget) {
					return console.log('[Trustindex Widget] content added in <head>');
				}

				element.contentHtml = html;

				// load rich snippet from widget
				if (!this.richSnippetLoaded) {
					let richSnippetMatch = /data-rich-snippet="([^"]+)"/.exec(html);
					if (richSnippetMatch && richSnippetMatch[1]) {
						this.richSnippetLoaded = true;
						this.addJS(this.getCDNUrl() + 'assets/js/richsnippet.js?' + richSnippetMatch[1]);
					}
				}

				if (element.isTriggerLoad(100)) {
					if (isActivityLoadEnabled) {
						window.tiElementToWaitForActivity.push(element);
					} else {
						new TrustindexWidget(null, element);
					}
				} else {
					// load floating widgets immediately
					if (isFloatingWidget) {
						new TrustindexWidget(null, element);
					} else {
						element.isWaitForVisibility = true;
						window.tiElementToWaitForActivity.push(element);
					}
				}
			})(scriptElement);
		});
	}

	static initWidgetsFromDom(forceLoad = false, index, callback)
	{
		let widgets = this.widgetsForInit || document.querySelectorAll('.ti-widget:not(.ti-certificate):not(.ti-feed-widget)' + (!forceLoad ? ':not([data-trustindex-widget])' : ''));
		if (typeof index !== 'undefined' && widgets.length) {
			if (this.widgetsForInit === undefined) {
				this.widgetsForInit = widgets;
			}

			new TrustindexWidget(widgets[index]);

			index++;
			if (index < widgets.length) {
				setTimeout(() => this.initWidgetsFromDom(forceLoad, index, callback), 20);
			} else {
				delete this.widgetsForInit;

				if (callback) {
					callback();
				}
			}
		} else {
			widgets.forEach(widget => new TrustindexWidget(widget));

			if (callback) {
				callback();
			}
		}
	}

	static async getWidgetHtml(element)
	{
		let url = this.getWidgetUrl(element.key) + 'content.html';
		if (element.key === 'wp-widget') {
			let templateId = element.getAttribute('data-template-id');
			if (templateId) {
				let template = document.getElementById(templateId);
				if (template) {
					element.removeAttribute('data-template-id');
					template.remove();

					// replace WP unnecessary added attributes
					return template.innerHTML
						.replace(/decoding="async"/g, '')
						.replace(/ (?:src|srcset|data-src|data-srcset|data-ti-src|data-ti-srcset|consent-original-src-_|consent-original-srcset-_|consent-original-data-src-_|consent-original-data-srcset-_)="(?!http)[^"]*"/g, '')
						.replace(/data-ti-src="[^"]+lazy\.(png|jpg|jpeg|gif|svg)"/g, '')

						.replace(/consent-original-class-_="/g, 'class="')
						.replace(/consent-original-src-_="/g, 'src="')
						.replace(/consent-original-srcset-_="/g, 'srcset="')
						.replace(/consent-original-data-src-_="/g, 'data-src="')
						.replace(/consent-original-data-srcset="/g, 'data-srcset="')

						.replace(/<noscript>[^<]+<\/noscript>/g, '')

						.replace(/data-(?:lazy-|ti-)?src="/g, 'src="')
						.replace(/data-(?:lazy-|ti-)?srcset="/g, 'srcset="')

						// new concept for lazyload plugins, previous can be deleted after 2026-01-01
						.replace(/<trustindex-image/g, '<img')
						.replace(/><\/trustindex-image>/g, '/>')
						.replace(/data-imgurl(set)?="/g, 'src$1="')
						;
				}
			}

			url = element.getAttribute('data-html-url');
		}

		let response = await fetch(url);
		if (!response.ok) {
			return false;
		}

		let html = await response.text();

		// add crossorigin="anonymus" to images for AMP widget
		if (location.href.indexOf(this.getCDNUrl() + 'amp-widget') !== -1) {
			html = html.replace(/<img/g, '<img crossorigin="anonymus"');
		}

		return html;
	}

	static destroyWidgets()
	{
		window.tiWidgetInstances.forEach(widgetInstance => widgetInstance.destroy());
		window.tiWidgetInstances = [];
	}
};
