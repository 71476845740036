export default class TrustindexFloatingWidget {
	constructor(widget)
	{
		this.widget = widget;

		if (sessionStorage.getItem('ti-widget-closed-'+this.widget.pid)) {
			this.widget.remove();
			this.widget.TrustindexWidget.destroy();
		}
	}

	click(event)
	{
		if (event.target.matches('.disable-widget')) {
			event.preventDefault();

			if (!Trustindex.isAdminUrl()) {
				sessionStorage.setItem('ti-widget-closed-'+this.widget.pid, true);

				this.widget.remove();
				this.widget.TrustindexWidget.destroy();
			}
		}
	}
};