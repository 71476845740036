export default class TrustindexReviewImageModule {
	constructor(widget)
	{
		let script = widget.querySelector('script.ti-lightbox-data[type="application/ld+json"]');
		this.data = JSON.parse(script.innerHTML).data;

		script.remove();

		this.widget = widget;
		this.callOneOriginalColumnResize = true;
	}

	format()
	{
		this.registerImageErrorEvent();
	}

	resize()
	{
		let imagesCanBePlaced;

		this.widget.reviewsContainer.querySelectorAll('.ti-review-item .ti-review-image-container').forEach(imageContainer => {
			let reviewItemId = imageContainer.closest('.ti-review-item').getAttribute('data-id');
			let images = this.data.filter(img => img.r === reviewItemId);

			if (!imagesCanBePlaced) {
				imagesCanBePlaced = Math.floor(imageContainer.offsetWidth / 70);
			}

			if (imagesCanBePlaced && images.length && parseInt(imageContainer.getAttribute('data-image-listed')) !== imagesCanBePlaced) {
				imageContainer.setAttribute('data-image-listed', imagesCanBePlaced);

				let html = '';
				for (let i = 0, min = Math.min(images.length, imagesCanBePlaced) - 1; i <= min; i++) {
					let hasMoreImage = i === min && i < images.length - 1;

					html += '<div class="ti-review-image'+ (hasMoreImage ? ' ti-has-more-image' : '') +'" data-image-index="'+ i +'" style="width: '+ (100 / imagesCanBePlaced).toFixed(2) + '%' + '">' +
						'<div class="ti-review-image-inner">' +
							'<img src="'+ images[i].thumbnail +'" loading="lazy" alt="" />' +
							(hasMoreImage ? '<div class="ti-more-image-count">+'+ (images.length - i - 1) +'</div>' : '') +
						'</div>' +
					'</div>';
				}
				imageContainer.innerHTML = html;

				this.registerImageErrorEvent();
			}
		});
	}

	toggleGalleryVisiblity()
	{
		let isCurrentlyShowingImages = Boolean(this.widget.reviewsContainer.querySelector('.ti-review-item:not(.ti-hidden-review) .ti-review-image-container'));
		this.widget.reviewsContainer.querySelectorAll('.ti-review-item').forEach((reviewItem, index) => {
			let imageContainer = reviewItem.querySelector('.ti-review-image-container');
			if (imageContainer) {
				imageContainer.style.transition = '';
			}

			reviewItem.classList.add('ti-hidden-review');

			if (index >= this.widget.TrustindexSliderWidget.state && index < this.widget.TrustindexSliderWidget.state + this.widget.TrustindexSliderWidget.visibleReviewNum + (this.widget.TrustindexSliderWidget.state < 0 ? 1 : 0)) {
				reviewItem.classList.remove('ti-hidden-review');

				if (imageContainer && isCurrentlyShowingImages) {
					imageContainer.style.transition = 'none';
				}
			}
		});
	}

	registerImageErrorEvent()
	{
		this.widget.reviewsContainer.querySelectorAll('.ti-review-image img').forEach(img => {
			img.addEventListener('error', event => {
				let image = event.target;
				let reviewItemId = image.closest('.ti-review-item').getAttribute('data-id');
				for (let i = 0, l = this.data.length; i < l; i++) {
					if (this.data[i].r === reviewItemId && this.data[i].thumbnail === image.src) {
						this.data.splice(i, 1);
						break;
					}
				}

				let imageGalleryContainer = image.closest('.ti-review-image-container');
				if (imageGalleryContainer) {
					imageGalleryContainer.removeAttribute('data-image-listed');
					return this.resize();
				}

				let container = image.closest('.ti-review-image');
				let remainingImages = this.data.filter(i => i.r === reviewItemId);

				if (remainingImages.length > 0) {
					image.src = remainingImages[0].thumbnail;

					container.querySelector('.ti-more-image-count').innerHTML = '+'+remainingImages.length-1;
					if (1 === remainingImages.length) {
						container.classList.remove('ti-has-more-image');
					}
				} else {
					container.remove();
				}
			});
		});
	}
};